<template>
  <gallery
    :category-name="$route.params.catName" 
    :gallery-name="$route.params.galleryName"
  />
</template>

<script>
import Gallery from '@/components/Gallery.vue'

export default {
  name: 'ShowGalleries',
  props: ['catName', 'catDisplayName'],
  components: {
    Gallery
  }
}
</script>

<template>
  <v-container grid-list-xl text-md-center>

    <!-- Title -->
    <v-layout row justify-center>
      <v-flex md12>
        <v-card-title primary-title class="justify-center">
          <h3 class="text--accent-2">{{ galleryDisplayName }}</h3>
        </v-card-title>
      </v-flex>
    </v-layout>

    <!-- Actual Picture Loop -->
    <v-layout row justify-center pa5>
      <v-flex xs12 md2 v-for="picture in this.pictures" :key="picture.picture">
        <v-hover v-slot:default="{ hover }">
          <v-card
            justify-center
            outlined
            :class="{ 'on-hover': hover }"
          >
            <v-img
              :src="getCoverImage(picture)"
              @click="showLightbox(picture.name)"
            ></v-img>
            <v-card-subtitle justify-space-between class="accent"></v-card-subtitle>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
    <lightbox id="lightbox"
      ref="lightbox"
      :directory="assetApiUrl"
      :images="pictures"></lightbox>
  </v-container>
</template>

<script>
import Lightbox from 'vue-my-photos'
import axios from 'axios'

  export default {
    name: 'Gallery',
    props: ['categoryName', 'galleryName'],
    components: {
      Lightbox
    },
    watch: {
      categoryName: function(newVal, oldVal) {
        console.log('Category changed from: ', oldVal, 'to ', newVal)
        this.getGallery()
      },
      galleryName: function(newVal, oldVal) {
        console.log('Category changed from: ', oldVal, 'to ', newVal)
        this.getGallery()
      }
    },

    mounted () {
      this.getGallery()
    },

    data () {
      return {
        baseurl: process.env.VUE_APP_API,
        galleryDisplayName: '',
        galleryCopyRight: '',
        rawpictures: [],
      }
    },

    computed: {
      apiGalleryPath: function() {
        return '/api/galleries/' + this.categoryName + '/' + this.galleryName
      },
      apiAssetsPath: function() {
        return '/assets'
      },
      galleryApiUrl: function() {
        return this.baseurl + this.apiGalleryPath
      },
      assetApiUrl: function() {
        return this.baseurl + this.apiAssetsPath
      },
      pictures: function() {
        /**
         * Adds properties needed for lightbox
         **/
        this.rawpictures.forEach(function(item, index, instance) {
          instance[index].name = item.picture
          instance[index].id = item.picture.split('/').join('_')
        });
        return this.rawpictures
      }
    },

    methods: {

      getGallery: function() {
        console.log("Fetching pictures from " + this.galleryApiUrl)

        axios({
          method: 'GET',
          'url': this.galleryApiUrl,
        }).then(result => {
          let data = result.data
          this.rawpictures = data.pictures
          this.galleryDisplayName = data.display_name
          this.galleryCopyright = data.copyright
        })
      },

      getCoverImage: function(picture) {
        /**
         * returns the url on which the cover picture can be fetched
         **/
        var url = this.assetApiUrl + picture.picture_thumbnail
        return url
      },

      showLightbox: function(imageName) {
        this.$refs.lightbox.show(imageName);
      },
    },

  }
</script>

<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}
</style>
